<template>
  <div class="com-home-QuestionThree">
    <img
      class="img0"
      src="../../../../public/img/QuestionSolution/QuestionThree/底纹.png"
    />
    <img
      class="img1"
      src="../../../../public/img/QuestionSolution/QuestionThree/主文案@2x.png"
    />
    <img
      class="img2"
      src="../../../../public/img/QuestionSolution/QuestionThree/点缀.png"
    />
    <img
      class="img3"
      src="../../../../public/img/QuestionSolution/QuestionThree/电脑.png"
    />
    <img
      class="img4"
      src="../../../../public/img/QuestionSolution/QuestionThree/手机.png"
    />
    <img
      class="img5"
      src="../../../../public/img/QuestionSolution/QuestionThree/扫描层.png"
    />
    <img
      class="img6"
      src="../../../../public/img/QuestionSolution/QuestionThree/扫描层_内容.png"
    />
    <img
      class="img7"
      src="../../../../public/img/QuestionSolution/QuestionThree/悬浮 文字@2x.png"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.$el.style.visibility = "visible";
      this.api.animateCSS(this.$el.querySelector(".img1"), "fadeInUp");
      this.api.animateCSS(this.$el.querySelector(".img3"), "fadeInUp");
      setTimeout(() => {
        const img4 = this.$el.querySelector(".img4");
        img4.style.display = "block";
        const img5 = this.$el.querySelector(".img5");
        img5.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img4"), "fadeInUp");
        this.api.animateCSS(this.$el.querySelector(".img5"), "fadeInUp");
      }, 500);
      setTimeout(() => {
        const img6 = this.$el.querySelector(".img6");
        img6.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img6"), "fadeIn");
      }, 900);
      setTimeout(() => {
        const img7 = this.$el.querySelector(".img7");
        img7.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img7"), "fadeInUp");
      }, 800);
      setTimeout(() => {
        const img2 = this.$el.querySelector(".img2");
        img2.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img2"), "fadeIn");
      }, 1500);
    }, 100);
  },
};
</script>
<style lang="less" scoped>
.com-home-QuestionThree {
  .img0 {
    margin-left: 100px;
    margin-top: 100px;
  }
  .img1 {
    width: 560px;
    margin-left: 160px;
    margin-top: 10px;
  }
  .img2 {
    display: none;
    margin-left: 160px;
    margin-top: 170px;
  }
  .img3 {
    margin-left: 180px;
    margin-top: 160px;
  }
  .img4 {
    display: none;
    margin-left: 180px;
    margin-top: 270px;
  }
  .img5 {
    display: none;
    margin-left: 520px;
    margin-top: 130px;
  }
  .img6 {
    display: none;
    margin-left: 575px;
    margin-top: 155px;
  }
  .img7 {
    display: none;
    width: 332px;
    margin-left: 475px;
    margin-top: 320px;
  }
  .img7 {
    --animate-duration: 1.5s;
  }
}
</style>
